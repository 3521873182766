import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import axios from "axios";
import { useConfig } from "@peracto/peracto-config";

let Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faList} size="6x" />
    </div>
);

const MenuItemsComponent = ({
    state: {
        menuItem,
        menuItemPath,
        layout = "grid",
        linkColour,
        linkFont,
        linkFontWeight = "normal",
        fallbackImage,
        categoryPath = "category",
    },
    isEditMode,
}) => {
    const config = useConfig();
    const API_URL = config.get("api");
    const [menuItemData, setMenuItemData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [showMobileList, setShowMobileList] = useState(false);

    useEffect(() => {
        if (menuItemPath) {
            const loadMenuItems = async () => {
                setLoadingError(false);
                const path =
                    menuItemPath[0] === "/"
                        ? menuItemPath.substr(1)
                        : menuItemPath;
                try {
                    axios
                        .get(`${API_URL}/menu-items-children-by-path/${path}`)
                        .then((res) => {
                            const data = res.data && res.data["hydra:member"];

                            if (data) {
                                setMenuItemData(data);
                                setIsLoading(false);
                            }
                        })
                        .catch((error) => {
                            setLoadingError(true);
                            return Promise.reject(error);
                        });
                } catch (e) {
                    console.error(e);
                    setLoadingError(true);
                }
            };
            loadMenuItems();
        }
    }, [API_URL, menuItemPath]);

    if (loadingError) {
        // this has padding so the element is at least clickable so it can be selected and edited
        return <div className="py-10 w-full"></div>;
    }

    const isGrid = layout === "grid";

    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <div
            className={`flex flex-wrap content-menu-items content__menu-items ${
                isGrid ? "flex-row -mx-[15px] mb-0" : "flex-col mx-0 mb-[15px]"
            }`}
        >
            {menuItemPath ? (
                <>
                    {!isGrid && menuItem.label && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            className="flex justify-between items-center text-[18px] mb-0 font-[600] cursor-pointer md:cursor-default content-menu-items__parent-title"
                            style={{
                                fontFamily: linkFont,
                                color: linkColour,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                setShowMobileList(!showMobileList);
                            }}
                            data-testid="content-menu-items__parent-title"
                        >
                            <p>{menuItem.label}</p>
                            <p className="inline-block md:hide">
                                <FontAwesomeIcon
                                    icon={showMobileList ? "minus" : "plus"}
                                />
                            </p>
                        </a>
                    )}
                    <div
                        className={
                            isGrid
                                ? "w-full grid gap-[15px] sm:gap-[30px] sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4"
                                : ""
                        }
                    >
                        {menuItemData?.length > 0
                            ? menuItemData.map((menuItem, index) => {
                                  if (
                                      !menuItem.path ||
                                      menuItem.itemType === "branch"
                                  ) {
                                      return null;
                                  }
                                  let path =
                                      !menuItem.path.startsWith("http") &&
                                      menuItemPath[0] !== "/"
                                          ? `/${menuItem.path}`
                                          : menuItem.path;

                                  if (
                                      menuItem.itemType === "category" &&
                                      !menuItem.path.startsWith(
                                          `/${categoryPath}/`
                                      )
                                  ) {
                                      path = `/${categoryPath}${path}`;
                                  }

                                  return (
                                      <div
                                          className={`content-menu-items__item flex-col content-center justify-start w-full md:flex
                                        ${
                                            !isGrid && !showMobileList
                                                ? "hide"
                                                : "flex"
                                        }
                                        ${
                                            isGrid
                                                ? "text-center"
                                                : "text-left px-0"
                                        }`}
                                          key={`menu_item_${index}`}
                                      >
                                          <a
                                              href={path}
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  if (isEditMode) return;

                                                  window.location = path;
                                              }}
                                              className={`content-menu-items__link active:outline-none active:shadow-none ${
                                                  isGrid
                                                      ? "!no-underline !text-brand-black  rounded-[5px] overflow-hidden"
                                                      : linkColour
                                                      ? `hover:text-[${linkColour}}`
                                                      : "hover:text-brand-black"
                                              }`}
                                              data-testid="content-menu-items__link"
                                          >
                                              {isGrid && (
                                                  <span
                                                      className={`block bg-cover bg-center bg-no-repeat pb-[100%] w-full content-menu-items__image ${
                                                          isGrid
                                                              ? ""
                                                              : "mb-[15px]"
                                                      }`}
                                                      title={menuItem.linkText}
                                                      style={{
                                                          backgroundImage: `url(${
                                                              menuItem.content
                                                                  ?.image ||
                                                              menuItem.image ||
                                                              fallbackImage ||
                                                              "/images/placeholder.jpg"
                                                          })`,
                                                          backgroundColor:
                                                              !menuItem.image &&
                                                              !fallbackImage &&
                                                              "#f7f7f7",
                                                      }}
                                                      data-testid="content-menu-items__image"
                                                  />
                                              )}

                                              <p
                                                  className={`content-menu-items__link-text ${
                                                      isGrid
                                                          ? "text-[16px] leading-[22px] p-[15px] bg-brand-white"
                                                          : "my-[5px]"
                                                  }`}
                                                  style={{
                                                      color: isGrid
                                                          ? ""
                                                          : linkColour ||
                                                            "#000",
                                                      fontWeight: isGrid
                                                          ? 600
                                                          : linkFontWeight,
                                                  }}
                                                  data-testid="content-menu-items__link-text"
                                              >
                                                  {menuItem.linkText}
                                              </p>
                                          </a>
                                      </div>
                                  );
                              })
                            : !isLoading && "No Menu Items"}
                    </div>
                </>
            ) : inStorefront ? (
                <></>
            ) : (
                <Icon />
            )}
        </div>
    );
};

export default MenuItemsComponent;
