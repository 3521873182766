import createPlugin from "./createPlugin";

import MenuItemsRenderer from "./Renderer/MenuItemsRenderer";
import MenuItemsForm from "./Form/MenuItemsForm";

const menuItemsPlugin = (settings) =>
    createPlugin({
        Renderer: MenuItemsRenderer,
        Controls: MenuItemsForm,
        ...settings,
    });

const menuItems = menuItemsPlugin();
export default menuItems;

export { menuItemsPlugin, MenuItemsRenderer };
