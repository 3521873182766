const sharedClasses =
    "flex items-center text-center justify-center text-[16px] leading-[1] rounded-[5px] px-[60px] py-[10px] font-normal transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25";

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-deep-grey text-brand-white hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary active:bg-brand-dark-stone-grey active:border-4 active:shadow-[0_0_0_4px_rgba(18,23,23,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border border-brand-deep-grey hover:bg-brand-deep-grey hover:text-brand-white active:bg-brand-primary active:border-4 active:border-brand-stone-grey active:shadow-[0_0_0_4px_rgba(18,23,23,0.4)]`,
    linkClasses: `inline-block !my-[5px] text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-primary`,
};

export const secondary = {
    solidClasses: `${sharedClasses} bg-none text-brand-black border border-brand-deep-grey hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary active:border-4 active:border-brand-stone-grey active:shadow-[0_0_0_4px_rgba(103,122,122,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border border-brand-deep-grey hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary active:border-4 active:border-brand-stone-grey active:shadow-[0_0_0_4px_rgba(103,122,122,0.4)]`,
    linkClasses: `inline-block !my-[5px] text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-dark-grey`,
};

export const tertiary = {
    solidClasses: `${sharedClasses} border border-brand-white bg-brand-deep-grey text-brand-white hover:bg-brand-white hover:text-brand-primary active:bg-brand-white active:shadow-[0_0_0_4px_rgba(18,23,23,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-primary border border-brand-primary hover:bg-brand-white hover:text-brand-primary active:shadow-[0_0_0_4px_rgba(0,167,181,0.4)]`,
    linkClasses: `inline-block !my-[5px]  text-[18px] leading-[20px] lg:text-[24px] lg:leading-[28px] text-brand-white`,
};
