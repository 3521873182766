import React, { InputHTMLAttributes } from "react";
import { Field } from "formik";
import { twMerge } from "tailwind-merge";

export type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  errorMessage?: string;
  hasError?: boolean;
};

export const Radio = (props: RadioProps) => {
  const {
    checked,
    name,
    label,
    disabled,
    hasError,
    className = "",
    ...restProps
  } = props;

  return (
    <div className={twMerge(``, className)} {...restProps}>
      <label
        className={`inline-flex items-center text-sm cursor-pointer gap-x-4 group ${
          disabled ? "opacity-20 !cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <div
          className={`flex items-center justify-center w-6 h-6 border rounded-full ${
            !checked ? "group-hover:border-brand-mocka" : ""
          } ${hasError ? "border-brand-danger" : "border-brand-primary"}`}
        >
          <input
            type="radio"
            checked={checked}
            disabled={disabled}
            name={name}
            className={`w-4 h-4 bg-transparent rounded-full appearance-none checked:bg-brand-mocha ${
              disabled ? "opacity-20 !cursor-not-allowed" : "cursor-pointer"
            }`}
            {...restProps}
          />
        </div>
        {label}
      </label>
    </div>
  );
};

export const FormikRadio = (props) => {
  const { label, errorMessage, required, hasError, ...restProps } = props;

  return (
    <div>
      {label?.length > 0 && (
        <label
          htmlFor={restProps?.name || restProps?.id}
          className={`block mb-2 font-medium text-base`}
        >
          {label}
          <>
            {required ? (
              <span className="text-brand-danger"> *</span>
            ) : (
              <span className="italic font-normal text-brand-primary">
                {" "}
                &#8212; optional
              </span>
            )}
          </>
        </label>
      )}
      <Field name={props.name}>
        {({ field, form }) => (
          <div className="space-y-3">
            {props.options.map((option, index) => (
              <Radio
                key={index}
                type="radio"
                id={option.value.replaceAll(" ", "-")}
                {...field}
                value={option.value}
                disabled={form.isSubmitting}
                checked={field.value === option.value}
                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                label={option.label}
              />
            ))}
          </div>
        )}
      </Field>
    </div>
  );
};
