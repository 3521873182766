import {
    Accordion,
    Carousel,
    Container,
} from "@peracto/peracto-blocks-tailwind";

import Products from "./HibProducts";
import MenuItems from "./HibMenuItems";
import Image from "./HibImage";

export default {
    plugins: {
        content: [Carousel, Accordion, Products, Image, MenuItems],
        layout: [Container],
    },
};
