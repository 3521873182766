import React from "react";

import { defaultSettings } from "./defaults/settings";
import Component from "./Component";
import Form from "./Form/MenuItemsForm";

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings };

    return {
        FormComponent: (props) => <Form {...props} />,

        Component: (props) => <Component {...props} {...mergedSettings} />,

        name: "peracto/content/menuItems",
        version: "1.0.0",
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            menu: {},
            menuItem: {},
            menuItemPath: null,
            categoryPath: null,
        }),
    };
};

export default createPlugin;
