const { darken, lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#0b090a"; // now Off Black was"#00A7B5";
const brandDeepGrey = "#343A40" // now Deep Grey was"#00A7B5";


const colors = {
    //Primary Colours
    "brand-primary": brandPrimaryColor,
    "brand-deep-grey": brandDeepGrey,
    "brand-mocha": "#7B5B4A",
    "brand-nude": "#B5917B",
    "brand-cream": "#F0E7DA",
    "brand-white": "#FFFFFF",

    //Additional Colours
    "brand-moss":"#353F33",
    "brand-fawn": "#918674",
    "brand-sand": "#C1B7A5",
    "brand-stone-grey": "#ECEBE9",
    "brand-alabaster": "#F4F3EE",

    //orginal colours
    "brand-success": "#2DB200",
    "brand-danger": "#EE3224",
    "brand-warning": "#F49C10",
    "brand-info": "#0079C2",


    //To remove
    "brand-pale-danger": "#FED7D7",
    "brand-light-grey": "#F7F7F7",
    "brand-grey": "#CCCCCC",
    "brand-dark-grey": "#808A94",

    // Hover colors
    "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
    "brand-primary-darken": darken(0.05, brandPrimaryColor),

    "brand-dark-grey-lighten": lighten(0.05, "#808A94"),
    "brand-dark-grey-darken": "#67707A",

    "brand-black-lighten": lighten(0.05, brandDeepGrey),
    "brand-black-darken": "#121717",
};

const fonts = {
    heading: ["DMSans"],
    body: ["DMSans"],
    light: ["SilkSerif"],
};

module.exports = { colors, fonts };
